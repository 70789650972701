<template>
  <b-card
    class="brandamount-edit-wrapper"
  >
    <!-- form -->
    <b-form id="brandamountForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="年"
            label-for="ba_year"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="ba_year"
              size="sm"
              v-model="brandamount.ba_year"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="月"
            label-for="ba_month"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="ba_month"
              size="sm"
              v-model="brandamount.ba_month"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售权团队Id"
            label-for="purchase_team_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="purchase_team_id"
              size="sm"
              v-model="brandamount.purchase_team_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="品牌ID"
            label-for="brand_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="brand_id"
              size="sm"
              v-model="brandamount.brand_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="近30天销售金额"
            label-for="amount_30"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="amount_30"
              size="sm"
              v-model="brandamount.amount_30"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购调整额度"
            label-for="amount_change"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="amount_change"
              size="sm"
              v-model="brandamount.amount_change"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购调整后额度"
            label-for="amount_changed"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="amount_changed"
              size="sm"
              v-model="brandamount.amount_changed"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import brandamountStore from './brandamountStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      brandamount: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('brandamount')) store.registerModule('brandamount', brandamountStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('brandamount')) store.unregisterModule('brandamount')
    })

    const edit = function() {
      store.dispatch('brandamount/edit', {id: this.id}).then(res => {
        this.brandamount = res.data.data
      })
    }

    const view = function() {
      store.dispatch('brandamount/view', {id: this.id}).then(res => {
        this.brandamount = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('brandamount/save', this.brandamount).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>